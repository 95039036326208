<script setup lang="ts">
import type { JobModel } from '@/types/jobModel';
import JobListItem from '@/views/career/JobListItem.vue';

defineOptions({
  name: 'CompanyTopJobs',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const { fetchAllJobsSorted, jobListRef } = useMopJob();
const topJobsRef: Ref<JobModel[]> = ref([]);
const MAX_ITEMS_LENGTH = 3;

onMounted(async () => {
  await fetchAllJobsSorted();

  if (jobListRef.value.length === 0) {
    return;
  }

  const vacanciesIds = props?.data?.items.map((job: { jobId: any }) => job.jobId);

  const topJobs = jobListRef.value.filter((jobRef) => vacanciesIds.includes(jobRef.getId()));
  const jobsNumberDifference = MAX_ITEMS_LENGTH - topJobs.length;
  const fallbackJobs = [];

  if (jobsNumberDifference > 0) {
    const latestJobs = jobListRef.value.filter((item) => !vacanciesIds.includes(item.getId()));

    for (let index = 0; index < jobsNumberDifference; index++) {
      fallbackJobs.push(latestJobs[index]);
    }
  }
  topJobsRef.value = [...topJobs, ...fallbackJobs];
});
</script>

<template>
  <div v-if="topJobsRef.length > 0" v-storyblok-editable="data" class="company-top-jobs">
    <div class="company-top-jobs__list">
      <JobListItem
        v-for="item in topJobsRef"
        :key="`top-jobs-${item.getId()}`"
        :job="item"
        class="company-top-jobs__item"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.company-top-jobs__list {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  grid-gap: $space-8;
  align-content: start;

  @include apply-upto(extralarge) {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }

  @include apply-upto(medium) {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }

  @include apply-upto(small) {
    grid-template-columns: 1fr;
  }
}

.company-top-jobs__item {
  border: 1px solid $black;

  @include apply-from(medium) {
    aspect-ratio: 5 / 6;
  }
}
</style>
